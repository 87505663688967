/*global process*/
/*eslint no-undef: "error"*/

import axios from 'axios';

export const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_PROJECT_TRACKER_API_URL,
});

export const axiosAsanaInstance = axios.create({
    baseURL: process.env.VUE_APP_ASANA_API_BASE_URL,
});

export const axiosBitbucketInstance = axios.create({
    baseURL: process.env.VUE_APP_BITBUCKET_API_URL,
});

export const axiosTrelloInstance = axios.create({
    baseURL: process.env.VUE_APP_TRELLO_API_BASE_URL,
});

export function getAuthorizationHeaders() {
    return {
        headers: { Authorization: `Bearer ${localStorage.getItem('auth_token')}` },
    };
}

export const fetchLocalDateTime = () => {
    const date = new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Manila'
    })
    const convertedDate = new Date(date)
    const userTimezoneOffset = convertedDate.getTimezoneOffset() * 60000
    return new Date(convertedDate.getTime() - userTimezoneOffset)
}